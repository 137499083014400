import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function MainMenu() {
  return (
    <StyledMainMenu>
      <p>
        This small utility website contains tools to assist with the setup of
        the Puerto Rico board game. This includes:
      </p>
      <ul>
        <li>
          <Link to={"building-randomiser"}>Expansion building randomiser</Link>
        </li>
        <li>
          <Link to={"rules"}>Rules</Link>
        </li>
      </ul>
    </StyledMainMenu>
  );
}

const StyledMainMenu = styled.article`
  li {
    margin-bottom: 0.5rem;
  }
`;
