import React from "react";
import styled from "styled-components";
import { Padding } from "Helpers/StylingConstants";
import { ReactComponent as BackSvg } from "Assets/back.svg";
import { Link, useLocation } from "react-router-dom";
import { Routes } from "Helpers/Routes";

export default function Header() {
  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <StyledHeader>
      <IconWrapper>
        {isHome ? (
          <img src="/logo192.png" alt="limajuice logo" />
        ) : (
          <Link to={Routes.MainMenu} title="back">
            <BackSvg />
          </Link>
        )}
      </IconWrapper>
      <StyledTitle>Rico Tools</StyledTitle>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  padding: ${Padding.Medium};
  background: whitesmoke;
`;

const StyledTitle = styled.h1`
  padding: 0;
  margin: 0;
`;

const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
  padding-right: ${Padding.Small};

  svg,
  img {
    width: 100%;
    height: 100%;
  }
`;
