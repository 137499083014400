export function groupBy<Value, GroupByType>(
  list: Value[],
  groupByPropertyAccessor: (value: Value) => GroupByType
) {
  const map = new Map<GroupByType, Value[]>();
  list.forEach((item) => {
    const key = groupByPropertyAccessor(item);
    let collection = map.get(key);
    if (collection === undefined) {
      collection = [];
      map.set(key, collection);
    }
    collection.push(item);
  });
  return map;
}
