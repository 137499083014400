import React, { useState } from "react";
import Building, {
  getAllVioletBuildings,
  getNumberPerVioletBuildingCost,
} from "Models/Building";
import BuildingList from "./Buildings/BuildingList";
import RandomSort from "Helpers/RandomSort";
import { groupBy } from "Helpers/CollectionHelpers";
import styled from "styled-components";

export default function BuildingRandomiser() {
  const [buildings, setBuildings] = useState<Building[]>();

  const chooseRandomValidBuildings = () => {
    const buildingsToChooseFrom = getAllVioletBuildings();
    const chosenBuildings: Building[] = [];
    const numberPerCostMap = getNumberPerVioletBuildingCost();
    const buildingCostMap = groupBy(
      buildingsToChooseFrom,
      (building) => building.cost
    );

    numberPerCostMap.forEach((numBuildingsForCost, cost) => {
      // Get all buildings and then randomly sort and take num needed
      let availableBuildingsForCost = buildingCostMap.get(cost);
      if (availableBuildingsForCost) {
        RandomSort(availableBuildingsForCost);
        availableBuildingsForCost = availableBuildingsForCost.slice(
          0,
          numBuildingsForCost
        );
        chosenBuildings.push(...availableBuildingsForCost);
      }
    });

    setBuildings(chosenBuildings);
  };

  let mainContent = undefined;
  if (buildings) {
    mainContent = (
      <>
        <BuildingList buildings={buildings} />
        <button onClick={() => setBuildings(undefined)}>Clear</button>
      </>
    );
  } else {
    mainContent = (
      <>
        <p>
          The Puerto Rico expansion adds additional buildings that can be
          interchanged with buildings of the same type. This page provides a
          quick way to randomise the swapping of these buildings to provide
          unique setups for each game.
        </p>
        <button onClick={chooseRandomValidBuildings}>
          Create random buildings list
        </button>
      </>
    );
  }

  return (
    <>
      <StyledSubHeading>Expansion building randomiser</StyledSubHeading>
      {mainContent}
    </>
  );
}

const StyledSubHeading = styled.h3`
  margin-top: 0;
`;
