import BuildingRandomiser from "Components/BuildingRandomiser";
import MainMenu from "Components/MainMenu";
import Rules from "Components/Rules";
import Header from "Components/Header";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import "normalize.css";
import { Padding } from "Helpers/StylingConstants";

export default function App() {
  return (
    <StyledApp>
      <BrowserRouter>
        <Header />
        <StyledMain>
          <Routes>
            <Route path="/" element={<MainMenu />} />
            <Route
              path="building-randomiser"
              element={<BuildingRandomiser />}
            />
            <Route path="rules" element={<Rules />} />
          </Routes>
        </StyledMain>
      </BrowserRouter>
    </StyledApp>
  );
}

const StyledApp = styled.article``;

const StyledMain = styled.main`
  padding: ${Padding.Medium};
`;
