import React from "react";
import styled from "styled-components";

export default function Rules() {
  return (
    <StyledRules>
      <StyledSubHeading>Rules</StyledSubHeading>
      <p>Rules for the base games and expansions are linked below:</p>
      <ul>
        <li>
          <a
            href="https://www.riograndegames.com/wp-content/uploads/2013/02/Puerto-Rico-Rules.pdf"
            target={"_blank"}
            rel={"noreferrer"}
          >
            Base game rules
          </a>
        </li>
        <li>
          <a
            href="https://www.riograndegames.com/wp-content/uploads/2019/01/PR-Expansion-Rules-small.pdf"
            target={"_blank"}
            rel={"noreferrer"}
          >
            Expansion rules
          </a>
        </li>
      </ul>
    </StyledRules>
  );
}

const StyledRules = styled.article`
  li {
    margin-bottom: 0.5rem;
  }
`;

const StyledSubHeading = styled.h3`
  margin-top: 0;
`;
