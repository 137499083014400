import React from "react";
import Building from "../../Models/Building";

interface BuildingListProps {
  buildings: Building[];
}

export default class App extends React.Component<BuildingListProps> {
  public render() {
    return (
      <ul>
        {this.props.buildings.map(building => (
          <li key={building.name}>{building.name}</li>
        ))}
      </ul>
    );
  }
}
