export default interface Building {
  cost: number;
  name: string;
  expansion: boolean;
}

export function getAllVioletBuildings(): Building[] {
  return [
    ...getAllOriginalVioletBuildings(),
    ...getAllExpansionVioletBuildings(),
  ];
}

export function getNumberPerVioletBuildingCost(): Map<number, number> {
  var costToNumBuildingsMap = new Map();
  costToNumBuildingsMap.set(1, 1);
  costToNumBuildingsMap.set(2, 2);
  costToNumBuildingsMap.set(3, 1);
  costToNumBuildingsMap.set(4, 1);
  costToNumBuildingsMap.set(5, 2);
  costToNumBuildingsMap.set(6, 1);
  costToNumBuildingsMap.set(7, 1);
  costToNumBuildingsMap.set(8, 2);
  costToNumBuildingsMap.set(9, 1);
  costToNumBuildingsMap.set(10, 5);

  return costToNumBuildingsMap;
}

function getAllOriginalVioletBuildings(): Building[] {
  return [
    {
      name: "Small market",
      cost: 1,
      expansion: false,
    },
    {
      name: "Hacienda",
      cost: 2,
      expansion: false,
    },
    {
      name: "Construction hut",
      cost: 2,
      expansion: false,
    },
    {
      name: "Small warehouse",
      cost: 3,
      expansion: false,
    },
    {
      name: "Hospice",
      cost: 4,
      expansion: false,
    },
    {
      name: "Office",
      cost: 5,
      expansion: false,
    },
    {
      name: "Large market",
      cost: 5,
      expansion: false,
    },
    {
      name: "Large warehouse",
      cost: 6,
      expansion: false,
    },
    {
      name: "Factory",
      cost: 7,
      expansion: false,
    },
    {
      name: "University",
      cost: 8,
      expansion: false,
    },
    {
      name: "Harbor",
      cost: 8,
      expansion: false,
    },
    {
      name: "Wharf",
      cost: 9,
      expansion: false,
    },
    {
      name: "Guild hall",
      cost: 10,
      expansion: false,
    },
    {
      name: "Residence",
      cost: 10,
      expansion: false,
    },
    {
      name: "Fortress",
      cost: 10,
      expansion: false,
    },
    {
      name: "Customs house",
      cost: 10,
      expansion: false,
    },
    {
      name: "City hall",
      cost: 10,
      expansion: false,
    },
  ];
}

function getAllExpansionVioletBuildings(): Building[] {
  return [
    {
      name: "Aqueduct",
      cost: 1,
      expansion: true,
    },
    {
      name: "Forest House",
      cost: 2,
      expansion: false,
    },
    {
      name: "Black Market",
      cost: 2,
      expansion: false,
    },
    {
      name: "Storehouse",
      cost: 3,
      expansion: false,
    },
    {
      name: "Guesthouse",
      cost: 4,
      expansion: false,
    },
    {
      name: "Church",
      cost: 5,
      expansion: false,
    },
    {
      name: "Trading Post",
      cost: 5,
      expansion: false,
    },
    {
      name: "Small Wharf",
      cost: 6,
      expansion: false,
    },
    {
      name: "Lighthouse",
      cost: 7,
      expansion: false,
    },
    {
      name: "Library",
      cost: 8,
      expansion: false,
    },
    {
      name: "Specialty Factory",
      cost: 8,
      expansion: false,
    },
    {
      name: "Union Hall",
      cost: 9,
      expansion: false,
    },
    {
      name: "Cloister",
      cost: 10,
      expansion: false,
    },
    {
      name: "Statue",
      cost: 10,
      expansion: false,
    },
  ];
}
